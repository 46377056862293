<template>
  <div class="IISP">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
    />
    <div class="content_center IISP-container">
      <div class="IISP-container-content">
        <div class="title">{{ content.title }}</div>
        <div class="desc">
          <p v-for="(text, index) in content.desc"  :key="index">{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BlockTitle from '@/components/BlockTitle.vue';
  export default {
    name: 'IISP',
    components: {
      BlockTitle,
    },
    data() {
      return {
        titleConfig: {
          cnName: '综服平台介绍',
          enName: 'PLATFORM INTRODUCTION',
        },
        content: {
          title: '海丰电商跨境电商综合服务平台',
          desc: [
            '海丰电商跨境电商综合服务平台，是通过先进的系统体系和管理功能，来构建最佳业务流程，实现企业管，理、业务单据整合的贸易流、物流、资金流、信息流四流合一的系统。通过综合整合金融机构、政府资源、物流公司、服务商及各业务板块，综服平台能够为企业提供一站式的进出口流程服务，同时依托平台数据及信保服务的覆盖，为企业提供一系列的进出口资金配套服务，解决企业尤其是中小微企业的融资难题。',
            '此外,外综服的核心目的, 是通过平台建设,加强企业的组织管理能力,实现企业精细化、 高效化管理转变,推动企业决策的科学化,提高地方外贸公司的核心竞争力和市场应变能力。'
          ]
        }
      }
    },
    mounted () {
    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
.IISP{
  margin: 100px auto;
  &-container{
    height: 400px;
    margin-top: 80px;
    background: url('~@/assets/images/about/IISP_bg.png') center center no-repeat;
    display: flex;
    justify-content: end;
    &-content{
      padding: 60px 60px;
      width: 660px;
      .title{
        margin-bottom: 20px;
        font-size: 24px;
        color: #FFFFFF;
      }
      .desc{
        p{
          line-height: 28px;
          margin-top: 30px;
          font-size: 16px;
          color: #FFFFFF;
          &:first-child{
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>