<template>
  <div class="Scope">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
      :isDesc="true"
      :descList="descList"
    />
    <div class="Scope-container">
      <div class="Scope-container-map" v-lazy:background-image="scope_bg"></div>
      <div class="Scope-container-sample" v-lazy:background-image="scope_sample"></div>
    </div>
  </div>
</template>

<script>
  import BlockTitle from '@/components/BlockTitle.vue';
  import scope_bg from '@/assets/images/about/scope_bg.jpg';
  import scope_sample from '@/assets/images/about/scope_sample.jpg';
  export default {
    name: 'Scope',
    components: {
      BlockTitle,
    },
    data() {
      return {
        titleConfig: {
          cnName: '做产业聚集的领跑者',
          enName: 'INDUSTRY LEADER',
        },
        descList: [
          '跨境电商作为外贸新业态，在拉动消费、促进流通方面作用显著。近年来，青岛市推动跨境电商快速发展成果突出。',
          '2021年，跨境电商进出口额超300亿元，6年间增长近5倍。',
          '在商务部“2021年跨境电子商务综合试验区评估”中，综合排名位列第一档，进入全国十强。'
        ],
        scope_bg,
        scope_sample,
      }
    },
  }
</script>

<style lang="scss" scoped>
.Scope{
  &-container{
    width: 1111px;
    height: 500px;
    margin: 30px auto 0;
    position: relative;
    &-map{
      position: absolute;
      left: 145px;
      top: 67px;
      width: 966px;
      height: 394px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    &-sample{
      position: absolute;
      left: 0;
      top: 0;
      width: 380px;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
</style>