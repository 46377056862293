<template>
  <div class="Company">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
    />
    <div id="Company_swiper" class="content_center Company-container" >
      <div class="Company-container-wrapper" :style="animation"  @mouseenter="mouseenter"
      @mouseleave="mouseleave">
        <template v-for="(company, index) in companyList">
          <div :key="`company${index}`" class="swiper-slide">
            <div class="img"><img v-lazy="company.image"></div>
            <div class="content">
              <!-- <div class="logo"><img :src="company.logo"></div> -->
              <div class="title">{{ company.adName }}</div>
              <div class="desc">{{ company.remark }}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <div id="Company_swiper" class="content_center Company-container">
      <div class="swiper-wrapper">
        <template v-for="(company, index) in companyList">
          <div :key="`company${index}`" class="swiper-slide">
            <div class="img"><img :src="company.url"></div>
            <div class="content">
              <div class="logo"><img :src="company.logo"></div>
              <div class="title">{{ company.title }}</div>
              <div class="desc">{{ company.desc }}</div>
            </div>
          </div>
        </template>
      </div>
    </div> -->
  </div>
</template>

<script>
  // import Swiper from "swiper/swiper-bundle";
  // import "swiper/swiper-bundle.css";
  import BlockTitle from '@/components/BlockTitle.vue';
  import andelie_logo from '@/assets/images/about/andelie_logo.png';
  import andelie_img from '@/assets/images/about/andelie_img.png';
  import aokeji_logo from '@/assets/images/about/aokeji_logo.png';
  import aokeji_img from '@/assets/images/about/aokeji_img.png';
  import taikkedianzi_logo from '@/assets/images/about/taikkedianzi_logo.png';
  import taikkedianzi_img from '@/assets/images/about/taikkedianzi_img.png';
  import yangma_logo from '@/assets/images/about/yangma_logo.png';
  import yangma_img from '@/assets/images/about/yangma_img.png';
  import xingdian_logo from '@/assets/images/about/xingdian_logo.png';
  import xingdian_img from '@/assets/images/about/xingdian_img.png';
  export default {
    name: 'Company',
    components: {
      BlockTitle,
    },
    data() {
      return {
        titleConfig: {
          cnName: '合作企业',
          enName: 'COOPERATIVE ENTERPRISE',
        },
        companyList: [
          {
            title: '安德烈斯蒂尔动力工具（青岛）有限公司',
            logo: andelie_logo,
            url: andelie_img,
            desc: '由世界最大的园艺机械生产商德国斯蒂尔集团投资建设，主要生产油锯、割草机、绿篱剪、高压清洗机等园林机械。2021年实现产值29.7亿元，同比增长22%；外贸进出口5.38亿美元，同比增长24%；缴纳税收10433万元，同比增长20%。2022年1-9月实现产值20.59亿元，同比下降5%。'
          },
          {
            title: '泰科电子（青岛）有限公司',
            logo: taikkedianzi_logo,
            url: taikkedianzi_img,
            desc: '由全球行业技术领军企业泰科电子集团投资建设，主要生产电子连接器和通信继电器系统。2021年实现年产值14亿元，同比增长37%；外贸进出口1亿美元，同比增长40%；缴纳税收4784万元，同比增长55.5%。2022年1-9月实现产值8.59亿元，同比下降16%。'
          },
          {
            title: '洋马发动机（山东）有限公司',
            logo: yangma_logo,
            url: yangma_img,
            desc: '由日本知名柴油发动机制造商洋马株式会社投资建设，主要生产多缸发动机。公司总投资2亿美元，是青岛市投资额最大的外资项目之一。2021年实现产值13.3亿元，外贸进出口9578万美元，缴纳税收5983万元。2022年1-9月实现产值5.76亿元，同比下降50%。'
          },
          {
            title: '青岛奥技科光学有限公司',
            logo: aokeji_logo,
            url: aokeji_img,
            desc: '由世界最大的滑雪眼镜生产商韩国奥技科集团投资建设，主要生产滑雪眼镜、摩托车眼镜、太阳镜。2021年实现产值4.4亿元，外贸进出口7206万美元，缴纳税收532万元。2022年1-9月实现产值4.52亿元，同比下降28%。'
          },
          {
            title: '星电高科技（青岛）有限公司',
            logo: xingdian_logo,
            url: xingdian_img,
            desc: '由日本知名电子信息企业星电株式会社投资建设，主要生产安全卡扣、小接口扬声器等汽车配件产品。2021年实现产值4.7亿元，外贸进出口8756万美元，缴纳税收603万元。2022年1-9月实现产值2.73亿元，同比下降30%。'
          }
        ],
        
        pStyle: {
          "animation": '',
          "animation-play-state": 'running'
        }
      }
    },
    computed: {
      animation() {
        let str = ''
        for (let [key, value] of Object.entries(this.pStyle)) {
          str += `${key}:${value}`
        }
        return str
      }
    },
  mounted () {
    // this.loadSwiper();
    this.getCompany();
  },
  methods: {
    async getCompany() {
      const res = await this.$request.get(`/ad/data/aboutUs_company`);
      if(res.data.length) {
        this.companyList = res.data;
        this.init();
      }
    },
    init() {
      this.$nextTick(() => {
        let animationHorseRaceLampWidth = document.getElementById('Company_swiper').offsetWidth;
        let translateX = (document.querySelector('#Company_swiper .Company-container-wrapper').offsetWidth - animationHorseRaceLampWidth) + 'px';
        if (parseInt(translateX) > 0) {
          this.pStyle.animation = `run ${parseInt(translateX)/parseInt(animationHorseRaceLampWidth)*30}s linear 0ms infinite;`
          document.styleSheets[0].insertRule(`
              @keyframes run {
                      0% {
                          -webkit-animation: translateY(0);
                          -moz-animation: translateY(0);
                          -o-transform: translateY(0);
                          -ms-transform: translateY(0);
                          transform: translateX(0);
                      }
                      100% {
                          -webkit-transform: translateX(-${translateX});
                          -moz-transform: translateX(-${translateX});
                          -o-transform: translateX(-${translateX});
                          -ms-transform: translateX(-${translateX});
                          transform: translateX(-${translateX});
                      }
                  }
          `, 1)
        }
      })
    },
    mouseenter() {
      this.$set(this.pStyle, "animation-play-state", 'paused')
    },
    mouseleave() {
      this.$set(this.pStyle, "animation-play-state", 'running')
    }
    // async loadSwiper() {
    //   // const res = await this.$request.get('/ad/data/home_banner');
    //   // this.bannerList = res;
    //   // new Swiper("#Company_swiper", {
    //   //   loop: true, // 循环模式选项

    //   //   // 如果需要分页器
    //   //   pagination: {
    //   //     el: ".swiper-pagination",
    //   //   },

    //   //   // // 如果需要前进后退按钮
    //   //   // navigation: {
    //   //   //   nextEl: ".swiper-button-next",
    //   //   //   prevEl: ".swiper-button-prev",
    //   //   // },

    //   //   autoplay: {
    //   //     delay: 5000,
    //   //     stopOnLastSlide: false,
    //   //     disableOnInteraction: true,
    //   //   },

    //   //   // 如果需要滚动条
    //   //   // scrollbar: {
    //   //   //   el: '.swiper-scrollbar',
    //   //   // },
    //   // });
    // },
    
  },
  }
</script>

<style lang="scss" scoped>
.Company{
  padding: 100px 0;
  margin: 100px auto;
  background-color: #F8F8FB;
  &-container{
    // width: 100%;
    height: 367px;
    margin-top: 80px;
    position: relative;
    overflow: hidden;
    // animation: run 3s linear 0ms infinite;
    &-wrapper{
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      .swiper-slide{
        display: flex;
        width: 530px;
        margin-left: 30px;
        position: relative;
        &:first-child{
          margin-left: 0;
        }
        .content{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 50px 50px;
          box-sizing: border-box;
          background-color: rgba($color: #0F5FD5, $alpha: 0.7);
          transform: translateY(100%);
          // background: url('~@/assets/images/about/company_bg.png') center center no-repeat;
          // .logo{
          //   position: absolute;
          //   top: 20px;
          //   right: 30px;
          //   width: 90px;
          //   height: 90px;
          //   img{
          //     width: 100%;
          //     height: 100%;
          //   }
          // }
          .title{
            margin-bottom: 20px;
            font-size: 22px;
            color: #fff;
          }
          .desc{
            line-height: 26px;
            font-size: 16px;
            color: #fff;
          }
        }
        &:hover{
          .content{
            -webkit-animation: start 0.6s linear;
            -moz-animation: start 0.6s linear;
            -o-animation: start 0.6s linear;
            -ms-animation: start 0.6s linear;
            animation: start 0.6s linear;
            -webkit-animation: translateY(0);
            -moz-animation: translateY(0);
            -o-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
          }
        }
      }
    }
    // --swiper-theme-color: #ff6600;
    // --swiper-pagination-color: #00ff33;
  }
}
@keyframes start {
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>