<template>
  <div class="About" v-lazy:background-image="bg_about">
    <div class="content_center">
      <div class="About-left">
        <div class="About-left-slogan">
          <img v-lazy="IconFeixin" alt="">&nbsp;&nbsp;
          <span>一站式服务·布局全球</span>
        </div>
        <div class="About-left-title_EN">ABOUT US</div>
        <div class="About-left-title_CN">{{ details.name }}</div>
        <div class="About-left-desc">
          <p v-for="(text, index) in details.remark.split('\n')" :key="index">{{ text }}</p>
          <!-- <p>青岛胶州湾综合保税区位于青岛市城阳区龙海路178号，前身为青岛出口加工区，于2003年3月经国务院批准设立，2019年9月经国务院批复升级为综合保税区，2020年7月封关运行，2020年10月完成功能区体制机制改革，推动跨境电商“三合一”监管中心建成投产，实现业态创新四个全省“首单”。2021年6月，在山东省率先开辟国际陆运货物通关新模式，将中欧班列“终点站”延伸至园区。</p> -->
          <!-- <p>青岛胶州湾综合保税区充分发挥“保税+”政策功能优势，着力发展保税加工、保税物流、保税服务“三大产业”和跨境电商、展示交易、保税维修、融资租赁“四大新型业务”，聚力成为具有全球影响力和竞争力的加工制造中心、研发设计中心、物流分拨中心、检测维修中心、销售服务中心“五大中心”，致力建设青岛市综合保税区业态创新发展先行先试的重要基地、山东省发展保税服务贸易的重要口岸、中日韩特色保税业务的重要平台，成为具有较强活力的国内国际“双循环”发展功能区。</p> -->

        </div>
        <!-- <div class="About-left-btn">查看全部 ></div> -->
      </div>
      <div class="About-center">
        <img v-lazy="details.image" alt="">
      </div>
      <div class="About-right" v-if="!isHide">
        <div class="value">{{ details.establishedDate.slice(0, 4) }}<span class="unit"> 年</span></div>
        <p class="label">成立于</p>
        <div class="line"></div>
        <div class="value">{{ details.area }}<span class="unit"> 平方公里</span></div>
        <p class="label">核心区面积</p>
        <div class="line"></div>
        <div class="value">{{ details.companyCount }}<span class="unit"> 家</span></div>
        <p class="label">现有企业</p>
      </div>
    </div>
  </div>
</template>

<script>
  import bg_about from '@/assets/images/home/bg_about.jpg';
  // import illustration_about from '@/assets/images/home/illustration_about.png';
  import IconFeixin from '@/assets/images/home/icon_feixin.png';
  export default {
    name: 'About',
    props: {
      isHide: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        IconFeixin,
        illustration_about: `${ this.$ossUrl }home/illustration_about.jpg${this.$ossHash}`,
        // bg_about: `${ this.$ossUrl }home/bg_about.jpg${this.$ossHash}`,
        bg_about,
        details: {
          name: '',
          remark: '',
          establishedDate: '',
          area: '',
          companyCount: '',
          address: '',
          email: '',
          mobile: '',
          image: '',
          officialQrcode: ''
        }
      }
    },
    mounted () {
      this.getAboutUs();
    },
    methods: {
      async getAboutUs() {
        const res = await this.$request.get(`/aboutUs/1`);
        this.details = res.data;
        this.$store.dispatch('setAboutUs', res.data)
      }
    },
  }
</script>

<style lang="scss" scoped>
.About{
  height: 730px;
  padding: 80px 0;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  .content_center{
    display: flex;
  }
  &-left{
    width: 530px;
    margin-right: 40px;
    &-slogan{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 220px;
      height: 48px;
      background: #0F5FD5;
      img{
        width: 16px;
        height: 16px;
      }
      span{
        font-size: 16px;
        color: #fff;
      }
    }
    &-title_EN{
      height: 68px;
      line-height: 68px;
      margin-top: 30px;
      font-size: 58px;
      font-family: AppleSystemUIFont;
      color: #171717;
    }
    &-title_CN{
      height: 42px;
      line-height: 42px;
      margin-top: 5px;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 700;
      color: #33333B;
    }
    &-desc{
      margin: 24px 0;
      p{
        line-height: 22px;
        font-size: 16px;
        color: #5F5F6A;
        letter-spacing: 0.5px;
      }
      p:first-child{
        margin-bottom: 20px;
      }
    }
    &-btn{
      width: 220px;
      height: 48px;
      line-height: 48px;
      border: 1px solid #909097;
      text-align: center;
      color: #5F5F6A;
      font-size: 16px;
    }
  }
  &-center{
    width: 540px;
    height: 370px;
    margin-top: 110px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  &-right{
    flex: 1;
    text-align: center;
    margin-top: 110px;
    .value{
      font-size: 49px;
      font-family: AppleSystemUIFont;
      color: #0F5FD5;
    }
    .label,
    .unit{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #5F5F6A;
    }
    .line{
      width: 220px;
      height: 1px;
      margin: 20px auto;
      background: #D0D0D9;
    }
  }
}
@media screen and (max-width: 1365px) {
  .About{
    .content_center{
      width: 1200px;
    }
  }
}
</style>