<template>
  <div class="AboutUs">
    <SubTopBanner :topBannerUrl="top_banner">
      <HeaderNav :navIndex="1" />
    </SubTopBanner>
    <About id="anchor_a" :isHide="true" />
    <IISP />
    <Company id="anchor_b" />
    <!-- <Summary id="anchor_b" /> -->
    <Scope id="anchor_c" />
    <!-- <NewSituation /> -->
    <!-- <Business /> -->
    <RegionalAdvantages id="anchor_f" />
    <ContactUs id="anchor_g" />
    <Footer />
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav.vue';
  import About from '../Home/About.vue';
  import IISP from './IISP.vue';
  import Company from './Company.vue';
  // import Summary from './Summary.vue';
  import Scope from './Scope.vue';
  // import NewSituation from './NewSituation.vue';
  // import Business from './Business.vue';
  import RegionalAdvantages from './RegionalAdvantages.vue';
  import ContactUs from './ContactUs.vue';
  import top_banner from '@/assets/images/about/top_banner.png';
  export default {
    name: 'AboutUs',
    components: {
      HeaderNav,
      About,
      IISP,
      Company,
      // Summary,
      Scope,
      // NewSituation,
      // Business,
      RegionalAdvantages,
      ContactUs,
    },
    data() {
      return {
        top_banner,
      }
    },
    mounted () {
      this.getBanner();
    },
    methods: {
      async getBanner() {
        const res = await this.$request.get(`/ad/data/aboutUs_banner`);
        if(res.data.length) {
          this.top_banner = res.data[0].image;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.AboutUs{
  overflow-x: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 1519px) {
  .AboutUs{
    ::v-deep(.ContactUs-map-container){
      width: 1280px;
    }
    ::v-deep(.ContactUs-form){
      .ContactUs-form-container{
        width: 1280px;
        .ContactUs-form-left{
          padding: 72px 20px;
        }
      }
    }
    ::v-deep(.Business){
      .Business-list{
        width: 1280px;
      }
    }
    ::v-deep(.IISP){
      .IISP-container{
        width: 1280px;
      }
    }
    ::v-deep(.Company){
      .Company-container{
        width: 1280px;
      }
    }
    ::v-deep(.NewSituation){
      .NewSituation-list{
        width: 1280px;
      }
    }
    ::v-deep(.RegionalAdvantages){
      &-map-container{
        width: 1280px;
      }
    }
  }
}
</style>