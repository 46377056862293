<template>
  <div class="RegionalAdvantages">
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
    />
    <div class="RegionalAdvantages-map-container">
      <div class="location_details">
        <template v-for="(item, index) in advantageList">
          <div :key="index" class="location_details-item">
            <div class="icon"><img :src="item.icon" alt=""></div>
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </template>
        <!-- <div class="location_details-left"></div>
        <div class="location_details-right">
          <div class="title">
            <p>基地周边交通便捷，</p>
            <p>机场、铁路、高速交通网络发达：</p>
          </div>
          <div class="list">
            <template v-for="(item, index) in list">
              <p :key="index">
                <span></span>
                <span>{{ item.name }}</span>
                <span>{{ item.value }} KM</span>
              </p>
            </template>
          </div>
        </div> -->
      </div>
      <div id="map-container" class="map_container">
        <div class="map_container-map"></div>
        <!-- <Map
          :isPulseLink="true"
          centerPointName="仓库地址"
          :points="pointsJson"
          :lines="lineJson"
          :zoom="10.5"
          :rotation="45"
          :centerPointSize="[3000, 3000]"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
  import BlockTitle from '@/components/BlockTitle.vue';
  // import Map from '../Statistics/LargeScreenSynthesis/Map.vue';
  import map_details from '@/assets/images/about/map_details.jpg';
  import icon_location from '@/assets/images/about/icon_location.png';
  import advantages_1 from '@/assets/images/about/advantages-1.svg';
  import advantages_2 from '@/assets/images/about/advantages-2.svg';
  import advantages_3 from '@/assets/images/about/advantages-3.svg';
  import advantages_4 from '@/assets/images/about/advantages-4.svg';
  import AMapLoader from '@amap/amap-jsapi-loader';
  import pointsJson from './points.json';
  import lineJson from './line.json';
  export default {
    name: 'RegionalAdvantages',
    components: {
      BlockTitle,
      // Map,
    },
    data() {
      return {
        titleConfig: {
          cnName: '业务优势',
          enName: 'BUSINESS ADVANTAGES',
        },
        advantageList: [
          {
            icon: advantages_1,
            title: '货物全程可视',
            desc: '自营电商物流系统，可自主查询货物全程轨迹，让运输过程更透明,客户更省心'
          },
          {
            icon: advantages_2,
            title: '高效稳定',
            desc: '自有船舶、一手仓位、船期稳定仓位充足，两端服务自有当地独立团队，保证派送时效与稳定性'
          },
          {
            icon: advantages_3,
            title: '政策导入',
            desc: '国有平台为主导，对接当地政府、综保区扶持政策的落地和兑现，为客户提供多样化服务选择'
          },
          {
            icon: advantages_4,
            title: '高性价比',
            desc: '略低于空运，远高于传统班轮运输的高效海运服务，货物安全性高，运费是空运的二分之一'
          }
        ],
        map_details,
        AMap: null,
        Loca: null,
        map: null,
        loca: null,
        icon_location,
        list: [
          { name: '距离胶东机场', value: 15 },
          { name: '青岛港', value: 20 },
          { name: '青岛北站', value: 20 },
          { name: '胶州北站', value: 20 },
          { name: '红岛火车站', value: 3 },
          { name: '青兰高速', value: 1 },
        ],
        pointsJson,
        lineJson
      }
    },
    mounted () {
      // this.$nextTick(() => {
      //   this.initMap();
      // })
    },
    methods: {
      initMap() {
        window._AMapSecurityConfig = {
          securityJsCode: 'f8219cd8a9397e5ffb04e11058f4dd07'
        }
        AMapLoader.load({
            "key": "bc7befd20138846d15039f98d1f6ce0e",             
            "version": "2.0",  
            "plugins": [],
            Loca:{
              version: '2.0'
            },
        }).then((AMap)=>{
            this.AMap = AMap;
          // eslint-disable-next-line no-undef
            this.Loca = Loca;
            this.map = new AMap.Map('map-container',  {
              zoom: 10,
              pitch: 40,
              rotation: 45,
              showLabel: false,
              viewMode: '3D',
              resizeEnable: true,
              center: [ 120.163381, 36.233834 ],
              mapStyle: 'amap://styles/bbf8c99c8020b8af3becf98a65a8a056',
            });
            this.loca = new this.Loca.Container({
              map: this.map,
            });
            this.loaderMap();
        }).catch(e => {
            console.log(e);
        })
      },
      loaderMap(){
        // 呼吸点
        var scatter = new this.Loca.ScatterLayer({
            loca: this.loca,
            zIndex: 10,
            opacity: 0.6,
            visible: true,
            zooms: [2, 22],
        });
        console.log(pointsJson);
        scatter.setSource(pointsJson);
        scatter.setStyle({
            unit: 'meter',
            size: [100000, 100000],
            borderWidth: 0,
            texture: 'https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png',
            duration: 2000,
            animate: true,
        });
        this.loca.add(scatter);

        // 弧线
        var pulseLink = new this.Loca.PulseLinkLayer({
            // loca,
            zIndex: 10,
            opacity: 1,
            visible: true,
            zooms: [2, 22],
            depth: true,
        });

        pulseLink.setSource(lineJson);
        pulseLink.setStyle({
            unit: 'meter',
            dash: [40000, 0, 40000, 0],
            lineWidth: function () {
                return [20000, 1000];
            },
            height: function (index, feat) {
                return feat.distance / 3 + 10;
            },
            // altitude: 1000,
            smoothSteps: 30,
            speed: function () {
                return 1000 + Math.random() * 200000;
            },
            flowLength: 100000,
            lineColors: function () {
                return ['rgb(255,228,105)', 'rgb(255,164,105)', 'rgba(1, 34, 249,1)'];
            },
            maxHeightScale: 0.3, // 弧顶位置比例
            headColor: 'rgba(255, 255, 0, 1)',
            trailColor: 'rgba(255, 255,0,0)',
        });
        this.loca.add(pulseLink);
        this.loca.animate.start();


        // // 点击事件处理
        // var clickInfo = new this.AMap.Marker({
        //     anchor: 'bottom-center',
        //     position: [116.396923, 39.918203, 0],
        // });
        // clickInfo.setMap(map);
        // clickInfo.hide();
        // map.on("click", function (e) {
        //     var feat = pulseLink.queryFeature(e.pixel.toArray());

        //     if (feat) {
        //         clickInfo.show();
        //         var props = feat.properties;
        //         clickInfo.setPosition(feat.coordinates[1]);
        //         clickInfo.setContent(
        //             '<div style="text-align: center; height: 20px; width: 150px; color:#fff; font-size: 14px;">' +
        //             '速率: ' + feat.properties['ratio'] +
        //             '</div>'
        //         );
        //     } else {
        //         clickInfo.hide();
        //     }
        // });

        // var dat = new Loca.Dat();
        // dat.addLayer(pulseLink);
      },
    },
  }
</script>

<style lang="scss" scoped>
.RegionalAdvantages{
  margin: 100px auto;
  &-map{
    padding: 140px 0 100px;
    &-container{
      display: flex;
      width: 1520px;
      margin: 80px auto 0;
      .location_details{
        width: 720px;
        padding: 20px 20px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        // background-color: #3484fb;
        &-item{
          width: 50%;
          padding: 20px 20px;
          box-sizing: border-box;
          .icon{
            width: 120px;
            height: 120px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .title{
            color: #171717;
            // color: #fff;
            font-size: 20px;
            font-weight: 700;
            margin: 15px 0;
          }
          .desc{
            line-height: 22px;
            font-size: 16px;
            color: #5F5F6A;
            // color: #fff;
          }
        }
        &-left{
          width:0px;
          height:0px;
          margin-top: 15px;
          margin-right: 10px;
          border: 16px solid transparent;/*以下四个样式对应四种三角形，任选其一即可实现*/
          border-left-color:#fff;
          /* border-right-color:lightseagreen; */
          // border-bottom-color:#fff;
        }
        &-right{
          .title{
            margin-bottom: 25px;
            p{
              font-size: 20px;
              color: #fff;
            }
          }
          .list{
            p{
              display: flex;
              justify-content: space-between;
              margin-bottom: 20px;
              span{
                font-size: 20px;
                color: #fff;
                &:nth-child(1){
                  width: 32px;
                  height: 32px;
                  background: url('~@/assets/images/about/icon_location.png') center center no-repeat;
                }
                &:nth-child(2){
                  flex: 1;
                  margin-left: 5px;
                  margin-right: 40px;
                }
                &:nth-child(3){
                  width: 100px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      .map_container{
        flex: 1;
        padding: 40px 0;
        background-color: #3484fb;
        &-map{
          width: 562px;
          height: 597px;
          margin: 0 auto;
          background-image:url('~@/assets/images/about/map.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        ::v-deep(.amap-markers){
          .amap-marker-label{
            padding: 25px 40px;
            background-color: #fff;
            border: 1px solid #fff;
            .marker-container{
              display: flex;
              &-left{
                width: 64px;
                height: 64px;
                background: url('~@/assets/images/about/location_icon.png');
              }
              &-right{
                .name{
                  margin-top: 15px;
                  font-size: 20px;
                  color: #171717;
                }
                .address{
                  line-height: 40px;
                  color: #5F5F6A;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>