<template>
  <div class="ContactUs" v-lazy:background-image="contact_us_bg">
    <!-- <div class="ContactUs-map">
      <BlockTitle
        :cnName="titleConfig.cnName"
        :enName="titleConfig.enName"
      />
      <div class="ContactUs-map-container">
        <div class="location_details" v-lazy:background-image="map_details">
          <div class="location_details-left"></div>
          <div class="location_details-right">
            <div class="title">
              <p>基地周边交通便捷，</p>
              <p>机场、铁路、高速交通网络发达：</p>
            </div>
            <div class="list">
              <template v-for="(item, index) in list">
                <p :key="index">
                  <span></span>
                  <span>{{ item.name }}</span>
                  <span>{{ item.value }} KM</span>
                </p>
              </template>
            </div>
          </div>
        </div>
        <div id="map-container" class="map_container"></div>
      </div>
    </div> -->
    <BlockTitle
      :cnName="titleConfig.cnName"
      :enName="titleConfig.enName"
    />
    <div class="ContactUs-form">
      <div class="ContactUs-form-container">
        <div class="ContactUs-form-left">
          <div class="form-title">
            <span>联系我们</span>
            <span>一站式服务·布局全球</span>
          </div>
          <div class="form-item">
            <label v-lazy:background-image="icon_name"></label>
            <input type="text" placeholder="请输入姓名" v-model="formData.name">
          </div>
          <div class="form-item">
            <label  v-lazy:background-image="icon_cellphone"></label>
            <input type="text" placeholder="请输入手机号" v-model="formData.phone">
          </div>
          <!-- <div class="form-item">
            <label  v-lazy:background-image="icon_code"></label>
            <input type="text" placeholder="请输入短信验证码" :value="formData.code">
            <span class="code">获取验证码</span>
          </div> -->
          <div class="form-item">
            <label  v-lazy:background-image="icon_company"></label>
            <input type="text" placeholder="企业名称（选填）" v-model="formData.company">
          </div>
          <div class="form-item">
            <label  v-lazy:background-image="icon_remark"></label>
            <input type="text" placeholder="需求描述（选填）" v-model="formData.remark">
          </div>
          <div class="form-item">
            <button class="btn" @click="handleSubmit">立即提交</button>
          </div>
        </div>
        <div class="ContactUs-form-right"><img :src="contact_sample" alt=""></div>
      </div>
    </div>
    <el-dialog
      :visible.sync="verfiyCodeVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="verfiyCode">
        <slide-verify
          :l="slideObj.l"
          :r="slideObj.r"
          :w="slideObj.w"
          :h="slideObj.h"
          slider-text="请拖动滑块完成验证再确认提交"
          @success="onSuccess"
          :imgs="imgs"
          :accuracy='5'
        ></slide-verify>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import BlockTitle from '@/components/BlockTitle.vue';
  import contact_us_bg from '@/assets/images/about/contact_us_bg.jpg';
  import contact_sample from '@/assets/images/about/contact_sample.jpg';
  import icon_name from '@/assets/images/about/icon_name.png';
  import icon_cellphone from '@/assets/images/about/icon_cellphone.png';
  import icon_code from '@/assets/images/about/icon_code.png';
  import icon_company from '@/assets/images/about/icon_company.png';
  import icon_remark from '@/assets/images/about/icon_remark.png';
  export default {
    name: 'ContactUs',
    components: {
      BlockTitle,
    },
    data() {
      return {
        titleConfig: {
          cnName: '联系我们',
          enName: 'CONTACT US',
        },
        contact_us_bg,
        contact_sample,
        icon_name,
        icon_cellphone,
        icon_code,
        icon_company,
        icon_remark,
        formData: {
          name: '',
          phone: '',
          company: '',
          remark: '',
        },
        verfiyCodeVisible: false,
        slideObj: {
          l: 42, //滑动碎片的大小
          r: 10, //滑动碎片的圆角
          w: 360, //画布的宽
          h: 180, //画布的高
        },
        imgs: [
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.pconline.com.cn%2Fimages%2Fupload%2Fupc%2Ftx%2Fitbbs%2F1411%2F03%2Fc11%2F40513136_1415006215595.jpg&refer=http%3A%2F%2Fimg.pconline.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=66cafeed4b05e3cc2337bd619564552a",
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F0146425bbdc6efa801213dead7d60e.jpg%401280w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=e11d669624d5ac428996e933f67f5d93",
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fb-ssl.duitang.com%2Fuploads%2Fitem%2F201804%2F28%2F20180428190056_cnorp.jpg&refer=http%3A%2F%2Fb-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=a174254b82136a546d08f4e903dddb59",
          "https://gss0.baidu.com/70cFfyinKgQFm2e88IuM_a/baike/pic/item/14ce36d3d539b6005fd3d015e550352ac65cb777.jpg",
          "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fbkimg.cdn.bcebos.com%2Fpic%2F18d8bc3eb13533fa828b4f630598ea1f4134970aed60&refer=http%3A%2F%2Fbkimg.cdn.bcebos.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1658045243&t=fb598fe1de3576cf76269f913d9fad0b",
        ], //可以将所需的图片放在一个数组中，刷新后组件会自动随机抽取图片
      }
    },
    mounted () {
    },
    methods: {
      handleSubmit(){
        const { name, phone } = this.formData;
        if(name && phone) {
          this.verfiyCodeVisible = true;
        } else {
          this.$message.warning('姓名和手机号不能为空');
        }
      },
      async onSuccess(){
        const res = await this.$request.post(`/feedback/create`, this.formData);
        if (res.success) {
          this.verfiyCodeVisible = false;
          this.$message.success('您的信息已提交成功');
        } else {
          this.$message.error('提交失败');
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.ContactUs{
  width: 100%;
  height: 812px;
  padding: 80px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &-form{
    margin-top: 60px;
    box-sizing: border-box;
    &-container{
      display: flex;
      justify-content: space-between;
      width: 1520px;
      margin: 0 auto;
    }
    &-left{
      flex: 1;
      width: 460px;
      padding: 72px 155px;
      background-color: #fff;
      .form-title{
        margin-bottom: 50px;
        span:first-child{
          margin-right: 12px;
          font-size: 24px;
          color: #202131;
        }
        span:last-child{
          font-size: 16px;
          color: #909097;
        }
      }
      .form-item{
        display: flex;
        width: 450px;
        margin-bottom: 18px;
        border-bottom: 1px solid #E2E2E8;
        position: relative;
        label{
          width: 18px;
          height: 18px;
          margin: 11px 10px 11px 0;
          background-position: center center;
          background-repeat: no-repeat;
        }
        input{
          width: 100%;
          height: 40px;
          line-height: 40px;
          padding-left: 10px;
          border: none;
        }
        .code{
          position: absolute;
          right: 0;
          height: 40px;
          line-height: 40px;
          color: #4A4A53;
          cursor: pointer;
        }
        .btn{
          width: 450px;
          height: 48px;
          line-height: 48px;
          background-color: #3484FB;
          text-align: center;
          font-size: 16px;
          color: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #3484FB;
          cursor: pointer;
          &:hover{
            background-color: rgba(52, 132, 251, 0.8);
          }
        }
      }
    }
    &-right{
      width: 760px;
      height: 572px;
    }
  }
}
</style>